import {
  TCareerType,
  TClassificationType,
  TFilterType,
  TSortType,
  TSubmissionStatusType,
} from '../../interface/recruit/IRecruitSetting';

export const FILTER_DEFINITION: Record<TFilterType, { KOR: string; ENG: string }> = {
  JOB_GROUP_AND_JOB: {
    KOR: '직군',
    ENG: 'Job Categories',
  },
  CAREER_TYPE: {
    KOR: '신입/경력',
    ENG: 'Career levels',
  },
  SUBMISSION_STATUS: {
    KOR: '접수상태',
    ENG: 'Application Status',
  },
  WORKING_AREA: {
    KOR: '근무지역',
    ENG: 'Working Area',
  },
};

export const SORT_DEFINITION: Record<
  TSortType,
  {
    KOR: string;
    ENG: string;
  }
> = {
  JOBFLEX_SORT: {
    KOR: '솔루션 정렬순',
    ENG: 'Solution Sort',
  },
  CREATED_DATE_TIME: {
    KOR: '최신순',
    ENG: 'Latest',
  },
  END_DATE_TIME: {
    KOR: '마감순',
    ENG: 'Closing Soon',
  },
  KOREAN: {
    KOR: '가나다순',
    ENG: 'A to Z',
  },
  SUBMISSION_STATUS: {
    KOR: '접수 상태순',
    ENG: FILTER_DEFINITION.SUBMISSION_STATUS.ENG,
  },
};

export const CLASSIFICATION_DEFINITION: Record<TClassificationType, string> = {
  CLASSIFICATION_CODE: '채용 구분',
  CAREER_TYPE: '신입/경력',
  JOB_GROUP_AND_JOB: '직군',
  WORKING_AREA: '근무지역',
  USER_PROVIDED: '직접입력',
};

export const CAREER_DEFINITION: Record<TCareerType, { KOR: string; ENG: string }> = {
  NEW: {
    KOR: '신입',
    ENG: 'Entry-level',
  },
  CAREER: {
    KOR: '경력',
    ENG: 'Experienced',
  },
  NEW_CAREER: {
    KOR: '신입/경력',
    ENG: 'Entry-level/Experienced',
  },
  INTERNSHIP: {
    KOR: '인턴',
    ENG: 'Internship',
  },
  ANY: {
    KOR: '경력 무관',
    ENG: 'all levels',
  },
  FIELD_DIFFERENCE: {
    KOR: '분야별 상이',
    ENG: 'Field difference',
  },
};

export const SUBMISSION_STATUS_DEFINITION: Record<TSubmissionStatusType, { KOR: string; ENG: string }> = {
  PRE_SUBMISSION: {
    KOR: '접수전',
    ENG: 'Before application',
  },
  IN_SUBMISSION: {
    KOR: '접수중',
    ENG: 'In progress',
  },
  POST_SUBMISSION: {
    KOR: '접수마감',
    ENG: 'Closed',
  },
};

export const RECRUITMENT_TYPE_DEFINITION = {
  GENERAL: {
    KOR: '일반채용',
    ENG: 'Hiring',
  },
  PERMANENT: {
    KOR: '상시채용',
    ENG: 'Ongoing',
  },
  RECOMMEND: {
    KOR: '추천채용',
    ENG: 'Recommended',
  },
  PRIVATE: {
    KOR: '비공개 채용',
    ENG: 'Confidential',
  },
};
export type TRecruitmentType = keyof typeof RECRUITMENT_TYPE_DEFINITION;
