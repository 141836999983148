import classnames from 'classnames/bind';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../common/button/Button';
import Checkbox from '../../common/checkbox/Checkbox';
import Paragraph from '../../common/paragraph/Paragraph';
import TextFieldBase from '../../common/textfield/TextFieldBase';
import { VIEW_MODE, ViewModeType } from '../../interface/common/ViewMode';
import { IQnAJOBDAForm, IQnAJOBFLEXForm, PositionList, RecruitmentField } from '../../interface/qna';
import { isEmailReg } from '../../util/reg';
import ModalQnAAgreement from './ModalQnAAgreement';
import style from './QnAPage.module.scss';
import QnAPositionJobflex from './QnAPositionJobflex';
import { SOLUTION } from '../../constants/solution/solution.constant';
import QnAPositionJobda from './QnAPositionJobda';
import { SOLUTION_TYPE } from '../../util/solution';
import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation';
import { LANGUAGE, Language } from '../../constants/language';

const cx = classnames.bind(style);

interface IProps {
  viewMode?: ViewModeType;
  isPreview?: boolean;
  positionList?: PositionList[];
  recruitmentFieldList?: RecruitmentField[];
  agreement?: {
    agreementLetterContents: string;
    isError: boolean;
  };
  onSubmit?: (data: IQnAJOBFLEXForm | IQnAJOBDAForm) => void;
  positionSn?: number | null;
  setPositionSn?: (positionSn: number | null) => void;
  positionRecruitmentFieldGroupSn?: number | null;
  setPositionRecruitmentFieldGroupSn?: (positionSn: number | null) => void;
}

export const QnAPage: FC<IProps> = ({
  viewMode,
  isPreview = false,
  positionList = [],
  recruitmentFieldList = [],
  onSubmit,
  agreement,
  positionSn,
  setPositionSn,
  positionRecruitmentFieldGroupSn,
  setPositionRecruitmentFieldGroupSn,
}) => {
  const searchParams = useSearchParams() as ReadonlyURLSearchParams;
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [phone, setPhone] = useState('');
  const [_agreement, setAgreement] = useState(false);
  const [showAgreement, setShowAgreement] = useState(false);
  const [error, setError] = useState({
    title: false,
    name: false,
    email: false,
    agreement: false,
    phone: false,
  });
  const [email, setEmail] = useState('');
  const [emailFocus, setEmailFocus] = useState(false);
  const [contents, setContents] = useState('');
  const [isRecruitmentFieldError, setIsRecruitmentFieldError] = useState(false);
  const languageType = (searchParams.get('languageType') as Language) ?? LANGUAGE.KOR;

  const atSymbol = (str: string) => {
    const match = str.match(/@/g);
    return match && match.length === 1;
  };

  const emailFilterList = () =>
    ['gmail.com', 'naver.com', 'hanmail.net', 'nate.com', 'hotmail.com'].filter((list) => {
      if (email.includes('@')) {
        const [, domain = ''] = email.split(atSymbol(email) ? '@' : /@(.+)/, 2);

        return list.startsWith(domain);
      }
      return true;
    });

  const QnAPosition = {
    [SOLUTION.JOBDA]: (
      <QnAPositionJobda
        isPreview={isPreview}
        positionList={positionList}
        setPositionSn={setPositionSn}
        positionSn={positionSn}
        options={{ languageType }}
      />
    ),
    [SOLUTION.JOBFLEX]: (
      <QnAPositionJobflex
        isPreview={isPreview}
        positionList={positionList}
        positionSn={positionSn}
        setPositionSn={setPositionSn}
        setPositionRecruitmentFieldGroupSn={setPositionRecruitmentFieldGroupSn}
        recruitmentFieldList={recruitmentFieldList}
        positionRecruitmentFieldGroupSn={positionRecruitmentFieldGroupSn}
        options={{ languageType }}
        isRecruitmentFieldError={isRecruitmentFieldError}
        setIsRecruitmentFieldError={setIsRecruitmentFieldError}
      />
    ),
  };

  return (
    <div className={cx('container', viewMode, { isPreview })}>
      <strong className={cx('title')}>{languageType === LANGUAGE.KOR ? '1:1 문의하기' : 'Contact Us'}</strong>
      <Paragraph className={cx('description')} variant={'B3'}>
        {languageType === LANGUAGE.KOR
          ? '1:1 문의에 대한 답변은 이메일로 회신받으실 수 있습니다.'
          : 'Responses to your inquiries will be sent to your email.'}
      </Paragraph>

      <form
        className={cx('form')}
        onSubmit={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          if (!onSubmit) return;
          if (
            !email ||
            !name ||
            !title ||
            !_agreement ||
            !isEmailReg(email) ||
            (!!phone && !/^01[0-9]-?[0-9]{4}-?[0-9]{4}$/.test(phone))
          ) {
            setError({
              title: !title,
              name: !name,
              email: !email || !isEmailReg(email),
              agreement: !_agreement,
              phone: !!phone && !/^01[0-9]-?[0-9]{4}-?[0-9]{4}$/.test(phone),
            });

            if (SOLUTION_TYPE === SOLUTION.JOBFLEX && positionSn && !positionRecruitmentFieldGroupSn) {
              setIsRecruitmentFieldError(true);
              return;
            }
            return;
          }

          try {
            await onSubmit(
              Object.assign(
                { title, contents, phone, email, name },
                positionSn
                  ? {
                      selectType: 'POSITION',
                      positionSn,
                      positionRecruitmentFieldGroupSn,
                    }
                  : { selectType: 'NORMAL' }
              ) as IQnAJOBFLEXForm
            );

            setName('');
            setPhone('');
            setAgreement(false);
            setTitle('');
            setEmail('');
            setContents('');
            setError({
              title: false,
              name: false,
              email: false,
              agreement: false,
              phone: false,
            });
          } catch (e) {
            throw e;
          }
        }}
      >
        {QnAPosition[SOLUTION_TYPE]}
        <div className={cx('form-grid-2')}>
          <div className={cx('form-grid-1')}>
            <Paragraph className={cx('form-label')} variant={'B2'} bold>
              <span className={cx('text', 'require')}>{languageType === LANGUAGE.KOR ? '이름' : 'Name'}</span>
            </Paragraph>
            <TextFieldBase
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                if (error.name) setError({ ...error, name: false });
              }}
              size={'lg'}
              placeholder={languageType === LANGUAGE.KOR ? '이름을 입력해주세요.' : 'Please enter your name'}
              maxLength={30}
              disabled={isPreview}
              error={!!error.name}
              errMessage={
                error.email ? (languageType === LANGUAGE.KOR ? '이름을 확인해주세요.' : 'Please check the Name') : ''
              }
            />
          </div>
          <div className={cx('form-grid-1')}>
            <Paragraph className={cx('form-label')} variant={'B2'} bold>
              <span className={cx('text')}>{languageType === LANGUAGE.KOR ? '연락처' : 'Contact Number'}</span>
            </Paragraph>
            <TextFieldBase
              value={phone}
              onChange={(e) => {
                setPhone(
                  e.target.value
                    .replace(/[^0-9]/g, '')
                    .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
                    .replace(/(\-{1,2})$/g, '')
                );
              }}
              onBlur={() => {
                if (!phone.length) {
                  setError({ ...error, phone: false });
                  return;
                }
                setError({
                  ...error,
                  phone: !/^01[0-9]-?[0-9]{4}-?[0-9]{4}$/.test(phone),
                });
              }}
              maxLength={13}
              size={'lg'}
              placeholder={
                languageType === LANGUAGE.KOR ? '연락처를 입력해주세요.' : 'Please enter your contact number'
              }
              disabled={isPreview}
              error={!!error.phone}
              errMessage={
                error.phone
                  ? languageType === LANGUAGE.KOR
                    ? '연락처를 확인해주세요.'
                    : 'Please check your contact number'
                  : ''
              }
            />
          </div>
        </div>

        <div className={cx('form-grid-1', { 'margin-48px': viewMode !== VIEW_MODE.MOBILE })}>
          <Paragraph className={cx('form-label')} variant={'B2'} bold>
            <span className={cx('text', 'require')}>{languageType === LANGUAGE.KOR ? '이메일' : 'Email'}</span>
          </Paragraph>
          <div className={cx('email-wrapper')}>
            <TextFieldBase
              className={cx('email-field')}
              onClick={() => {
                setEmailFocus(true);
              }}
              onBlur={() => {
                setError({
                  ...error,
                  email: !email.length || !isEmailReg(email),
                });
                setEmailFocus(false);
              }}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              size={'lg'}
              placeholder={languageType === LANGUAGE.KOR ? '이메일을 입력해주세요.' : 'Please enter your Email'}
              disabled={isPreview}
              autoComplete={'off'}
              error={error.email}
              errMessage={
                error.email
                  ? languageType === LANGUAGE.KOR
                    ? '이메일 양식을 확인해주세요.'
                    : 'Please check the Email format'
                  : ''
              }
            />
            {emailFocus && !!email.length && !!emailFilterList().length && !isEmailReg(email) && (
              <div className={cx('email-list-wrapper')}>
                <ul className={cx('email-list')}>
                  {emailFilterList().map((item) => {
                    let searchEmail = `${email}@${item}`;

                    if (email.includes('@')) {
                      const [input] = email.split('@', 2);
                      searchEmail = `${input}@${item}`;
                    }

                    return (
                      <li
                        key={item}
                        onMouseDown={(e) => {
                          const [input] = email.split('@', 2);

                          e.stopPropagation();
                          setEmail(`${input}@${item}`);
                          setError({ ...error, email: false });
                          setEmailFocus(false);
                        }}
                      >
                        <Paragraph variant={'B3'}>{searchEmail}</Paragraph>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className={cx('form-grid-1')}>
          <p className={cx('qna-title')}>{languageType === LANGUAGE.KOR ? '문의하기' : 'Submit Inquiry'}</p>

          <div className={cx('form-grid-1', 'margin-16px')}>
            <Paragraph className={cx('form-label')} variant={'B2'} bold>
              <span className={cx('text', 'require')}>{languageType === LANGUAGE.KOR ? '제목' : 'Subject'}</span>
              <Paragraph variant={'B4'} className={cx('title-length')}>
                {(title ?? '').length} / 50
              </Paragraph>
            </Paragraph>
            <TextFieldBase
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                if (error.title) setError({ ...error, title: false });
              }}
              size={'lg'}
              placeholder={
                languageType === LANGUAGE.KOR
                  ? '문의 제목을 입력해주세요.'
                  : 'Please enter the subject of your inquiry.'
              }
              maxLength={50}
              disabled={isPreview}
              error={error.title}
              errMessage={
                error.title
                  ? languageType === LANGUAGE.KOR
                    ? '문의 제목을 입력해주세요.'
                    : 'Please enter the subject of your inquiry.'
                  : ''
              }
            />
          </div>

          <div className={cx('form-grid-1', 'margin-12px')}>
            <Paragraph className={cx('form-label')} variant={'B2'} bold>
              <span className={cx('text')}>{languageType === LANGUAGE.KOR ? '내용' : 'Message'}</span>
            </Paragraph>
            <textarea
              onChange={(e) => {
                setContents(e.target.value);
              }}
              value={contents}
              className={cx('textarea')}
              placeholder={languageType === LANGUAGE.KOR ? '문의 내용을 입력해주세요.' : 'Please enter your message.'}
              disabled={isPreview}
            />
          </div>
          {/* NOTE: release/3.4.0 이후 작업될 내용 */}
          {/*<Button*/}
          {/*  type={'button'}*/}
          {/*  variant={'outlined'}*/}
          {/*  className={cx('upload-button')}*/}
          {/*  leftIcon={'upload_line'}*/}
          {/*  color={'teal'}*/}
          {/*>*/}
          {/*  <Paragraph variant={'B3'} bold>*/}
          {/*    이미지 첨부하기*/}
          {/*  </Paragraph>*/}
          {/*</Button>*/}

          <div className={cx('checkbox')}>
            <div className={cx('checkbox-top')}>
              <Checkbox
                id={'agreement'}
                onChange={(check) => {
                  setAgreement(check);
                  if (error.agreement) setError({ ...error, agreement: false });
                }}
                checked={_agreement}
                disabled={isPreview}
              />
              <label className={cx('check-label')} htmlFor={'agreement'}>
                <p className={cx('agreement')}>
                  {languageType === LANGUAGE.KOR
                    ? '개인정보 수집 및 이용에 동의합니다. (필수)'
                    : 'I agree to the collection and use of my personal information. (Required)'}
                </p>
              </label>
              {!isPreview && (
                <button type={'button'} className={cx('link')} onClick={() => setShowAgreement(true)}>
                  <span>{languageType === LANGUAGE.KOR ? '자세히' : 'More'}</span>
                </button>
              )}
            </div>
            <div>
              {error.agreement && (
                <Paragraph className={cx('error')} variant={'B4'}>
                  {languageType === LANGUAGE.KOR
                    ? '필수 약관에 동의해주세요.'
                    : 'Please agree to the required terms and conditions'}
                </Paragraph>
              )}
            </div>
          </div>
        </div>

        <Button type={'submit'} size={'lg'} disabled={isPreview}>
          {languageType === LANGUAGE.KOR ? '등록하기' : 'Submit'}
        </Button>
      </form>
      <ModalQnAAgreement
        show={showAgreement}
        viewMode={viewMode}
        setCheckAgreement={() => setAgreement(true)}
        close={() => {
          setShowAgreement(false);
          if (error.agreement) setError({ ...error, agreement: false });
        }}
        agreement={agreement}
      />
    </div>
  );
};

export default QnAPage;
